import { Box, Grid, TextField, InputLabel } from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { upperCaseFirstLetter } from '../../helpers';
import { FieldType } from '../types';
import { CommonDataContext } from '../../context';

export const CustomTextField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel?: string;
  isNeedToMoveLabelOutOfInput?: boolean;
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const [isInvalid, setIsInvalid] = useState(false);
  const formik: FormikProps<any> = useFormikContext();
  const { isMobileView, highlightErrorFieldsState } = useContext(CommonDataContext);
  displayLabel = displayLabel || _.get(fieldConfig, `displayLabel.${locale}`, '');

  const evaluateIfInvalid = (val: string): boolean => {
    return !!fieldConfig.required && _.isEmpty(val);
  };

  const handleChange = useCallback((e) => {
    const upperCasedValue = upperCaseFirstLetter(e.target.value);
    setFieldValue(upperCasedValue);
  }, []);

  const handleBlur = useCallback((e) => {
    const trimmedValue = _.trim(e.target.value);
    setFieldValue(trimmedValue);
  }, []);

  const setFieldValue = (valueToSet: string) => {
    //e.target.value = valueToSet;
    formik.setFieldValue(itemData.config.fieldName, valueToSet, false);
    setIsInvalid(evaluateIfInvalid(valueToSet));
  };

  const _resetField = (): void => {
    // Check if error is already set to ''
    if (Boolean(_.get(formik.errors, itemData.config.fieldName))) {
      //Remove Error
      formik.setFieldError(itemData.config.fieldName, '');
    }

    if (!_.has(formik.values, itemData.config.fieldName)) { // do not set '' value for untouched fields
      return;
    }

    //Erase value
    formik.setFieldValue(itemData.config.fieldName, '');
  };

  useEffect(() => {
    return () => {
      formik.setFieldError(itemData.config.fieldName, '');
    };
  }, []);

  useEffect(() => {
    //When there is no need to display the field, we reset it's state to a clean one

    if (!itemData.config.displayed) {
      _resetField();
    } else {
      let isEmpty = false;
      if (itemData.config.required) {
        isEmpty = _.isEmpty(value);
      }
      formik.setFieldError(
        itemData.config.fieldName,
        isInvalid || isEmpty ? 'required' : ''
      );
    }
  }, [itemData.config.displayed, itemData.config.required, isInvalid, value]);

  useEffect(() => {
    if (highlightErrorFieldsState.state) {
      setIsInvalid(!!formik.getFieldMeta(itemData.config.fieldName).error);
    }
  }, [highlightErrorFieldsState]);

  return (
    <Box
      component={Grid}
      item
      xl={_.get(fieldConfig, 'columns.xl', 12)}
      lg={_.get(fieldConfig, 'columns.lg', 12)}
      md={_.get(fieldConfig, 'columns.md', 12)}
      sm={_.get(fieldConfig, 'columns.sm', 12)}
      xs={_.get(fieldConfig, 'columns.xs', 12)}
      display={{
        xl: !itemData.config.displayed ? 'none' : 'block',
        lg: !itemData.config.displayed ? 'none' : 'block',
        md: !itemData.config.displayed ? 'none' : 'block',
        sm: !itemData.config.displayed ? 'none' : 'block',
        xs: !itemData.config.displayed ? 'none' : 'block',
      }}
    >
      {isNeedToMoveLabelOutOfInput && <InputLabel className='outer-label'>{displayLabel}</InputLabel>}
      <TextField
        key={fieldId}
        name={fieldConfig.fieldName}
        disabled={fieldConfig.disabled}
        fullWidth={fieldConfig.fullWidth}
        variant={isMobileView ? "outlined" : (fieldConfig.variant ?? "standard")}
        error={isInvalid}
        label={isNeedToMoveLabelOutOfInput ? '' : displayLabel}
        helperText={_.get(fieldConfig, `helperText.${locale}`, '')}
        required={fieldConfig.required}
        value={value ? value : ''}
        inputProps={{
          maxLength: fieldConfig.maxLength ?? null
        }}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Box>
  );
};
