import React, { useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Element } from '../../Element';
import { hasFieldsErrors } from '../../../helpers';
import { FormikErrors, FormikValues } from "formik";
import { useIntl } from "react-intl";
import { FieldType } from "../../types";


interface Props {
  handleSubmit: (values: FormikValues) => {};
  groupedFields: Record<string, FieldType[]>;
  setGroupedFields: any,
  values: FormikValues,
  errors: FormikErrors<FormikValues>
}

export const ReferenceForm = ({
  handleSubmit,
  groupedFields,
  setGroupedFields,
  values,
  errors,
}: Props) => {

  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    if (!values.security || values.security === "none") {
      return;
    }

    const securityField = groupedFields.security.find(field => field.config.fieldName === "security");
    const securityFieldLabel = securityField!.config.fieldOptions!.find(o => o.optionValue === values.security)!.displayLabel.en;

    setGroupedFields((prev: Record<string, FieldType[]>) => ({
      ...prev,
      security: prev.security.map((field: any) => {
        if (field.config.fieldName === "securityInfo") {
          return {
            ...field,
            config: {
              ...field.config,
              displayLabel: {
                ...field.displayLabel,
                en: securityFieldLabel
              }
            }
          }
        }
        return field;
      })
    }));
  }, [values.security]);


  return (
    <Grid item container spacing={4}>
      {groupedFields.common.map(fieldConfig => <Element
        key={fieldConfig.id}
        item={fieldConfig}
        locale={intl.locale}
      />)}

      <Grid item xs={12}>
        <Typography variant='subtitle2' className={classes.sectionSubtitle}>Manner Of Payment</Typography>
      </Grid>
      {groupedFields.mannerOfPayment.map(fieldConfig => <Element
        key={fieldConfig.id}
        item={fieldConfig}
        locale={intl.locale}
      />)}

      <Grid item xs={12}>
        <Typography variant='subtitle2' className={classes.sectionSubtitle}>Security</Typography>
      </Grid>
      {groupedFields.security.map(fieldConfig => <Element
        key={fieldConfig.id}
        item={fieldConfig}
        locale={intl.locale}
      />)}

      <Grid item xs={12}>
        <Typography variant='subtitle2' className={classes.sectionSubtitle}>Comments:</Typography>
      </Grid>
      {groupedFields.comments.map(fieldConfig => <Element
        key={fieldConfig.id}
        item={fieldConfig}
        locale={intl.locale}
      />)}

      <Grid item xs={12}>
        <Typography variant='subtitle2' className={classes.sectionSubtitle}>Reference Completed By:</Typography>
      </Grid>
      {groupedFields.completedBy.map(fieldConfig => <Element
        key={fieldConfig.id}
        item={fieldConfig}
        locale={intl.locale}
      />)}

      <Grid item container justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={(e: any) => handleSubmit(values)}
          disabled={hasFieldsErrors(errors)}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => {
  return {
    sectionSubtitle: {
      "&.MuiTypography-root": {
        margin: '0 0 -20px 0',
      }
    },
  };
});
