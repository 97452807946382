import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import enFlag from '../assets/images/en.png';
import spFlag from '../assets/images/sp.png';
import ruFlag from '../assets/images/ru.png';
import paFlag from '../assets/images/pa.png';
import frFlag from '../assets/images/fr.png';
import _ from 'lodash';

const languages = {
  en: require('../i18n/en.json'),
  es: require('../i18n/es.json'),
  ru: require('../i18n/ru.json'),
  pa: require('../i18n/pa.json'),
  fr: require('../i18n/fr.json'),
};

const useStyles = makeStyles(() => ({
  langSelector: {
    position: 'fixed',
    top: '3px',
    right: '10px',
    padding: '5px',
    background: '#FFF',
    borderRadius: '15px',
    '@media (max-width: 768px)': {
      position: 'absolute',
      borderRadius: '0',
      top: '20px',
      right: '20px',
      zIndex: '2',
    },
  },
  langBtn: {
    margin: '0 5px',
  },
}));

const LanguageProvider = (props: any) => {
  const classes = useStyles();

  const { vendorProfile, partnerId } = props;
  const availableLanguages = ['en', 'es', 'ru', 'pa', 'fr'];

  const [enabled, setEnabled] = useState(false);
  const savedLangChoice = localStorage.getItem('lang');
  const browserLanguage = navigator.language.split('-')[0];
  const languageToUse = _.includes(availableLanguages, browserLanguage)
    ? browserLanguage
    : 'en';

  const [locale, setLocale] = useState(languageToUse);
  const [lang, setLang] = useState(languages['en']);

  useEffect(() => {
    if (vendorProfile) {
      if (
        vendorProfile.altLanguageOca === 'show' ||
        vendorProfile.altLanguageOca === 'trial'
      ) {
        setEnabled(true);
      }

    }

    if (partnerId) {
      setEnabled(false);
      return;
    }

    if (!enabled) return;
    //Ask first if we have saved user's choice
    if (savedLangChoice) {
      if (savedLangChoice === 'es') {
        setLang(languages['es']);
        setLocale('es');
      } else {
        setLang(languages['en']);
        setLocale('en');
      }
      return;
    }
    //fallback to english
    localStorage.setItem('lang', 'en');

    //unless spanish is the browser's choice
    switch (languageToUse) {
      case 'es':
        setLang(languages['es']);
        setLocale(languageToUse);
        localStorage.setItem('lang', 'es');
        break;
      case 'ru':
        setLang(languages['ru']);
        setLocale(languageToUse);
        localStorage.setItem('lang', 'ru');
        break;
      case 'pa':
        setLang(languages['pa']);
        setLocale(languageToUse);
        localStorage.setItem('lang', 'pa');
        break;
      case 'fr':
        setLang(languages['fr']);
        setLocale(languageToUse);
        localStorage.setItem('lang', 'fr');
        break;
      default:
        setLang(languages['en']);
        setLocale('en');
        localStorage.setItem('lang', 'en');
    }

  }, [vendorProfile, enabled]);

  const handleChange = (langSelected: string) => {
    setLocale(langSelected);
    switch (langSelected) {
      case 'en':
        setLang(languages['en']);
        localStorage.setItem('lang', 'en');
        break;
      case 'es':
        setLang(languages['es']);
        localStorage.setItem('lang', 'es');
        break;
      case "ru":
        setLang(languages['ru']);
        localStorage.setItem('lang', 'ru');
        break;
      case "pa":
        setLang(languages['pa']);
        localStorage.setItem('lang', 'pa');
        break;
      case "fr":
        setLang(languages['fr']);
        localStorage.setItem('lang', 'fr');
        break;
    }
  };

  return (
    <IntlProvider locale={locale} messages={lang}>
      {enabled ? (
        <div className={classes.langSelector}>
          <Tooltip title="English" arrow>
            <Fab
              key="eng_btn"
              color={locale === 'en' ? 'primary' : 'default'}
              size="small"
              className={classes.langBtn}
              aria-label="english"
              onClick={() => handleChange('en')}
            >
              <img src={enFlag} alt="EN" />
            </Fab>
          </Tooltip>
          <Tooltip title="Spanish" arrow>
            <Fab
              key="spa_btn"
              color={locale === 'es' ? 'primary' : 'default'}
              size="small"
              className={classes.langBtn}
              aria-label="spanish"
              onClick={() => handleChange('es')}
            >
              <img src={spFlag} alt="ES" />
            </Fab>
          </Tooltip>
          <Tooltip title="Russian" arrow>
            <Fab
              key="ru_btn"
              color={locale === 'ru' ? 'primary' : 'default'}
              size="small"
              className={classes.langBtn}
              aria-label="russian"
              onClick={() => handleChange('ru')}
            >
              <img src={ruFlag} alt="RU" />
            </Fab>
          </Tooltip>
          <Tooltip title="French" arrow>
            <Fab
              key="fr_btn"
              color={locale === 'fr' ? 'primary' : 'default'}
              size="small"
              className={classes.langBtn}
              aria-label="French"
              onClick={() => handleChange('fr')}
            >
              <img src={frFlag} alt="fr" />
            </Fab>
          </Tooltip>
          <Tooltip title="Panjabi" arrow>
            <Fab
              key="pa1_btn"
              color={locale === 'pa' ? 'primary' : 'default'}
              size="small"
              className={classes.langBtn}
              aria-label="Panjabi"
              onClick={() => handleChange('pa')}
            >
              <img src={paFlag} alt="pa" />
            </Fab>
          </Tooltip>
        </div>
      ) : null}
      {props.children}
    </IntlProvider>
  );
};

export default LanguageProvider;
