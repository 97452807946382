import _ from 'lodash';
import { FieldType, RulesSatisfactionType, RuleType } from '../components/types';
import { Constants } from "../constants"
import { BusinessOwnerInput, SectionTypes } from '../api/types';
import { CreditAppSensitiveFieldsToFormat, UpdateCreditApplicationV2Input } from '@trnsact/trnsact-shared-types';


const getURLParameter = (paramKey: string, defaultValue?: string) => {
  const paramValue = new URL(window.location.href).searchParams.get(paramKey);
  if (paramValue) return paramValue;
  if (defaultValue) return defaultValue;
  return "";
};

const sanitizeString = (str: string) => {
  str = str.replace(/[^a-z0-9áéíóúñü .,_-]/gim, "");
  return str.trim();
};

const isValueBetween = (x: number, min: number, max: number): boolean => {
  return x >= min && x <= max;
};

const upperCaseFirstLetter = (value: string) =>
  value.slice(0, 1).toUpperCase() + value.slice(1, value.length);

const getContrastColor = (hexcolor: string) => {
  if (hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq >= 128 ? "black" : "white";
  }
};

const DEFAULT_LOCALES = ["en", "es"];
const DEFAULT_LOCALE = "en";
const isDefaultLocale = (locale: string) =>
  DEFAULT_LOCALES.indexOf(locale) !== -1;

const ruleEvalTypeRun = (rule: RuleType, val: any): boolean => {
  const isValueSet = !!val || val === false || val === 0;
  if (!isValueSet) {
    return false;
  }
  const getFormatEvalValuesFn = (val: any): Function => {
    if (typeof val === 'number') {
      return _.toNumber;
    }
    if (typeof val === 'string') {
      return _.toString;
    }
    return (val: any) => val;
  };

  switch (rule.evalType) {
    case 'have_value':
    case 'have_not_value':
    case 'contain':
      const isInverse = rule.evalType === 'have_not_value';
      const formatEvalValuesFn = _.isArray(val)
        ? getFormatEvalValuesFn(_.first(val))
        : getFormatEvalValuesFn(val);
      const evalValuesFormatted = _.map(rule.evalValue, formatEvalValuesFn);
      const result = _.isArray(val)
        ? _.some(evalValuesFormatted, target => _.includes(val, target))
        : _.includes(evalValuesFormatted, val);
      return isInverse ? !result : result;
    case 'eq':
      return rule.evalValue === val;
    case 'lt':
      return safeParseToInt(rule.evalValue) > safeParseToInt(val);
    case 'gt':
      return safeParseToInt(rule.evalValue) < safeParseToInt(val);
    case 'gte':
      return safeParseToInt(rule.evalValue) <= safeParseToInt(val);
    case 'lte':
      return safeParseToInt(rule.evalValue) >= safeParseToInt(val);
    case 'neq':
      return rule.evalValue !== val;

    default:
      throw new Error(
        `Eval rule could not be identified, i don't know how to process: ${rule.evalType}`
      );
  }
};

const checkIfRulesSatisfied = (
  formValues: any,
  rules: Array<RuleType>,
  rulesSatisfactionType?: RulesSatisfactionType
): boolean => {
  //No rules, we just default into displaying the element
  if (_.isEmpty(rules) || _.isEmpty(rulesSatisfactionType)) {
    return true;
  }

  const rulesEvaluationResults: Array<boolean> = rules.map(
    (rule: RuleType) => {
      const targetValue = _.get(formValues, `[${rule.targetName}]`);
      return ruleEvalTypeRun(rule, targetValue);
    }
  );

  switch (rulesSatisfactionType) {
    case 'all':
      //Uses "every" for ALL
      return _.every(rulesEvaluationResults);
    case 'at_least_one':
      //Filter results and count elements to match "AT LEAST ONE"
      return _.size(_.filter(rulesEvaluationResults, (o) => o === true)) >= 1;
    case 'exactly_one':
      //Filter results and count elements to match "EXACTLY ONE"
      return _.size(_.filter(rulesEvaluationResults, (o) => o === true)) === 1;
    case 'none':
      //Length of rules applied returned with False, are equal to
      return _.size(_.filter(rulesEvaluationResults, (o) => o === false)) ===
        _.size(rulesEvaluationResults);

    default:
      throw new Error(
        `Satisfaction rule level could not be identified, i don't know how to process: ${rulesSatisfactionType}`
      );
  }
};

// method also consider currency values
const safeParseToInt = (value: string | number) => {
  return _.isString(value)
    ? _.toNumber(value.replace(/[^\d.]/g, ''))
    : _.toNumber(value);
};

// find owner with the same email as POC. 
const getOwnerTheSameAsPOC = (pocEmail: string, owners: any) => {
  return pocEmail && _.find(owners, o => _.toLower(o.email) === _.toLower(pocEmail));
};

const checkIfIsSignaturesSection = (sectionConfig: any) => {
  return sectionConfig.sectionType === Constants.DynamicSignaturesSectionType ||
    sectionConfig.title.type && sectionConfig.title.type.startsWith('signatures');
};

const checkIfPersonalGuaranteeSignaturesSection = (sectionConfig: any) => sectionConfig.sectionType === SectionTypes.PersonalGuaranteeSignatures;

const checkIfIsOwnersSection = (sectionConfig: any) => sectionConfig.title.type && sectionConfig.title.type.startsWith('owners');

const checkIfCoApplicantSection = (sectionConfig: any) => sectionConfig.sectionType === SectionTypes.CoApplicant;

const checkIfDynamicReferenceSection = (sectionConfig: any) =>
  _.get(sectionConfig, "sectionType") === SectionTypes.DynamicReference;

const hasFieldsErrors = (errorFields: any) => {
  //After the filtering if array is empty, it means we have no errors
  return !_.isEmpty(
    //From Objects to Array
    Object.values(errorFields)
      //Flatten to uni-dimensional array
      .flatMap((e: any) => {
        if (typeof e === 'object') {
          /*             const depth1 = Object.values(e).map((a: any) => Object.values(a));
                      return depth1; */
          return _(e).values().compact().map(a => _.values(a)).value(); // To Check
        } else {
          return e;
        }
      })
      //2nd level flatten to uni-dimensional array
      .flat()
      //Filter if error description is "" or says "required"
      .filter((e: string) => e)
  );
};

const getOwnerFullNameByWhoIsPresentToSignKey = (key: string, formik: any) => {
  const isPOCKey = key === Constants.WhoIsPresentToSignPOCKey;
  let ownerData;
  if (isPOCKey) {
    ownerData = _.get(formik, `values`);
  } else {
    ownerData = _.get(formik, `values.owners[${key}]`);
  }

  const signorFirstName = _.get(ownerData, `firstName`, '');
  const signorLastName = _.get(ownerData, `lastName`, '');
  return `${signorFirstName} ${signorLastName}`;
};

const getAvailableToSignPersonsFieldOptions = (
  formik: any,
  isIndividualApp: boolean,
  isPersonalGuaranteeSignaturesSection?: boolean
) => {
  let owners: any = _.get(formik, 'values.owners', []);
  owners = _(owners)
    .compact()
    .filter((o: any) => o.firstName || o.lastName)  // if initial URL points to OCAv3 and then redirects to OCAv4, it adds owners with empty FN and LN
    .value();

  const ownersOptions = owners.map((owner: any, idx: number) => {
    return {
      displayLabel: {
        en: `${owner.firstName} ${owner.lastName}`,
        es: `${owner.firstName} ${owner.lastName}`,
      },
      optionValue: _.toString(idx),
      metaData: {
        email: owner.email,
      },
    };
  });
  const isPOCOwnerOrOfficer = _.get(formik, 'values.isOwnerOrOfficer', false) ||
    !!getOwnerTheSameAsPOC(_.get(formik, 'values.email'), owners);

  if (isIndividualApp || isPOCOwnerOrOfficer || isPersonalGuaranteeSignaturesSection) {
    // do not include pc only
    return ownersOptions;
  }
  const pocOption = {
    displayLabel: {
      en: `${_.get(formik, 'values.firstName', '')} ${_.get(formik, 'values.lastName', '')}`,
      es: `${_.get(formik, 'values.firstName', '')} ${_.get(formik, 'values.lastName', '')}`,
    },
    optionValue: Constants.WhoIsPresentToSignPOCKey,
    metaData: {
      email: _.get(formik, 'values.email', ''),
    },
  };
  return [pocOption, ...ownersOptions];
};
const convertToDate = (dateString: string) => {
  let newDate = new Date(dateString);
  let dd = newDate.getDate();
  let mm = newDate.getMonth() + 1;
  let ddFormatted = "0"
  let mmFormatted = "0";
  let yyyy = newDate.getFullYear();
  if (dd < 10) ddFormatted += dd;
  if (mm < 10) mmFormatted += mm;
  return `${mmFormatted}-${ddFormatted}-${yyyy}`;
};

const hexToRgb = (hex: any) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
};

const formatForUpdateCreditAppV2Payload = (apiMappingPayload: any): UpdateCreditApplicationV2Input => {

  const cleanupFromNullValues = (updateCreditAppV2Payload: any) => {
    // set undefined in order to not overwrite existing values with null
    const itemsToCleanup = [
      [updateCreditAppV2Payload],
      updateCreditAppV2Payload.equipments,
      updateCreditAppV2Payload.owners,
      updateCreditAppV2Payload.references
    ];
    const removeFromPayloadIfNeeded = (item: any, value: any, key: string) => {
      if (value === null) {
        _.set(item, key, undefined);
      }
    };

    _.forEach(itemsToCleanup, (items) => {
      _.forEach(items, item => {
        _.forEach(item, (value, key) => removeFromPayloadIfNeeded(item, value, key));
      });
    });
  }

  const updateCreditAppV2Payload = _.cloneDeep(apiMappingPayload);
  cleanupFromNullValues(updateCreditAppV2Payload);


  // those fields are not relevant for UpdateCreditAppV2, or we don't want to update at all
  const keysToRemove: string[] = [
    'sendCreditApplicationSignaturesTasks',
    'sendPersonalGuaranteeSignaturesTasks',
    'sendDocumentsSignaturesTasks',
    'ocaTemplateId',
    'bizOrIndividual',
    'rawValues',
    'ocaType',
    'ocaEnv',
    'disclosure',
    'draft',
    'dynamicsContactId',
    'formId',
    'locationId',
    'attemptToSubmit',
    'lenderProfileId',
    'applicationType',
    'existingCustomer',

    'percOwner',
    'driversLicenseUpload',
    'monthlyHousingPayment',
    'presAddress2',
    'timeAtAddressInMonths',
    'timeAtAddressInYears',
    'partnerId',
    'partnerDealerId',
  ];
  keysToRemove.forEach((key: string) => {
    delete updateCreditAppV2Payload[key];
  });

  return updateCreditAppV2Payload;
};

const updateMaskedFieldsConfig = (ocaConfig: any) => {
  const allSections = _.flatMap(ocaConfig, 'sections');
  const allFields = _.flatMap(allSections, 'fields');
  allFields.forEach((field: FieldType) => {
    let fieldName = _.replace(field.config.fieldName, 'owners[1].', '');
    const ocaSensitiveFieldsToFormat = [
      ...Object.values(CreditAppSensitiveFieldsToFormat),
      'driverLicenseNum'  // ambigius - driverLicenseNum on OCA form, but driversLicenseNum for API
    ];
    if ((ocaSensitiveFieldsToFormat as string[]).includes(fieldName)) {
      field.originalType = field.type;
      field.type = Constants.maskedFieldType;
      field.config.fieldName = `${fieldName}${Constants.maskedFieldNameSuffix}`;
    }
  });
};

export {
  hexToRgb,
  convertToDate,
  getURLParameter,
  sanitizeString,
  upperCaseFirstLetter,
  getContrastColor,
  DEFAULT_LOCALES,
  DEFAULT_LOCALE,
  isDefaultLocale,
  isValueBetween,
  ruleEvalTypeRun,
  checkIfRulesSatisfied,
  safeParseToInt,
  getOwnerTheSameAsPOC,
  checkIfIsSignaturesSection,
  checkIfPersonalGuaranteeSignaturesSection,
  checkIfIsOwnersSection,
  hasFieldsErrors,
  checkIfCoApplicantSection,
  checkIfDynamicReferenceSection,
  getAvailableToSignPersonsFieldOptions,
  getOwnerFullNameByWhoIsPresentToSignKey,
  formatForUpdateCreditAppV2Payload,
  updateMaskedFieldsConfig,
};
