import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormikContext, FormikProps } from 'formik';
import _ from 'lodash';
import { useEffect, useState, useContext } from 'react';
import { Element } from './Element';
import { EquipmentViewOnly, Owners, Signatures } from './sections';
import { FieldType, SectionType } from './types';
import { useIntl } from 'react-intl';
import { Constants } from "../constants"
import { DocumentsSigningSection } from './sections/DocumentsSigningSection';
import { checkIfIsOwnersSection, checkIfIsSignaturesSection, getOwnerTheSameAsPOC } from '../helpers';
import { CommonDataContext } from "../context";
import { BusinessOwnerInput } from '../api/types';

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontWeight: 500,
    fontFamily: 'Source Sans Pro, sans-serif',
  },
}));

export const OCAv4Section = ({
  config,
  vendorContactId,
  locationId,
  setRepData,
  setRepList,
  template,
  externalData = {},
}: {
  config: SectionType;
  vendorContactId?: string;
  locationId?: string;
  setRepData: any;
  setRepList: any;
  template: any;
  externalData?: any;
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { publicAccount } = useContext(CommonDataContext);

  const [multiLocationEnabled, setMultiLocationEnabled] = useState(false);
  const formik: FormikProps<any> = useFormikContext();
  const repList = _.get(publicAccount, 'salesReps', []);
  const locationsList = _.get(publicAccount, 'locations', []);
  const vendorGUID = _.get(publicAccount, 'dynamicsAccountId', null);
  const primaryContact = _.get(publicAccount, 'primaryContact', null);
  const isSignaturesSection = checkIfIsSignaturesSection(config);
  const isOwnersSection = checkIfIsOwnersSection(config);
  //@ts-ignore
  const isDocumentsSigningSection = config.sectionType === Constants.DocumentSignSectionType;
  const isPersonalGuaranteeSignaturesSection = config.sectionType === Constants.PersonalGuaranteeSignaturesSectionType;
  const sectionHeader = _.get(config, `title.config.displayLabel.${intl.locale}`, '');
  const isIndividualApp = _.has(externalData, 'isIndividualApp')
    ? _.get(externalData, 'isIndividualApp')
    : _.get(formik, 'values.bizOrIndividual') === 'individual';
  const isDisplayed = config.config.displayed;


  //Any external data that is not available through Formik context
  //can be added here
  const [externalDataState, setExternalDataState] = useState(//hotfix - useState to not update on each render
    {
      ...externalData,
      repList,
      vendorContactId,
      locationsList,
      locationId,
      vendorGUID,
      primaryContact,
      isIndividualApp,
      activeTemplate: template,
      sectionConfig: config,
    });
  const externalCallbacks = { setRepData, setRepList };

  useEffect(() => {
    if (isIndividualApp) {
      return;
    }

    if ((isOwnersSection || isSignaturesSection) && !_.get(formik, 'values.owners')) {
      const ownerToSet: any = {};
      formik.setFieldValue('owners', [ownerToSet], false);
    }
  }, []);


  useEffect(() => {
    if (externalDataState.isNeedToOmitOcaSpecificHandlingOwnersLogic) {
      // not required on task pages
      return;
    }

    if (isIndividualApp && (isSignaturesSection || isDocumentsSigningSection)) {
      handleOwnersForIndividualOCA();
    }

    if (!isIndividualApp && (isSignaturesSection || isPersonalGuaranteeSignaturesSection)) {
      handleOwnersForBusinessOCA();
    }
  }, []);

  useEffect(() => {
    setMultiLocationEnabled(
      _.get(publicAccount, 'vendorProfile.multiLocationManagement', false)
    );
  }, [publicAccount]);

  const getPointOfContact = (): BusinessOwnerInput => {

    const keysToNotPopulateForBusiness = [
      "address",
      "address2",
      "city",
      "state",
      "postalCode",
      "prevAddress",
      "prevAddress2",
      "prevCity",
      "prevState",
      "prevPostalCode",
    ];

    const fieldsToPopulateFromCreditApp = isIndividualApp
      ? Constants.ApplicantFields
      : Constants.ApplicantFields.filter(key => !keysToNotPopulateForBusiness.includes(key));

    return _.reduce(fieldsToPopulateFromCreditApp, (result, fieldKey) => {
      const value = _.get(formik, `values.${fieldKey}`, "");
      if (value) {
        _.set(result, fieldKey, value);
      }
      return result;
    }, { isOwnerPc: true });
  }

  const handleOwnersForIndividualOCA = () => {
    const pointOfContact = getPointOfContact();
    const haveCoApplicant = _.get(formik, `values.haveCoApplicant`) === 'yes';
    if (haveCoApplicant) {
      formik.setFieldValue('owners[0]', pointOfContact);
    } else {
      formik.setFieldValue('owners', [pointOfContact]); // leave only primary contact
    }
    const ownersQuantity = haveCoApplicant ? 2 : 1;
    formik.setFieldValue('ownersQuantity', ownersQuantity, false);
  };

  const handleOwnersForBusinessOCA = () => {
    const owners = _.get(formik, 'values.owners', []);
    const ownerTheSameAsPOC = getOwnerTheSameAsPOC(_.get(formik.values, 'email'), owners);

    const pointOfContact = getPointOfContact();
    if (ownerTheSameAsPOC) {
      // mark exisitng owner record as isOwnerPc. Populate it with the data mapped to pointOfContact
      const pocIdx = _.findIndex(owners, ownerTheSameAsPOC);
      if (pocIdx < 0) {
        return;
      }
      const owner = owners[pocIdx];
      formik.setFieldValue(`owners[${pocIdx}].isOwnerPc`, true);
      _.forEach(pointOfContact, (pocValue: any, pocKey: string) => {
        if (!_.has(owner, pocKey)) {
          formik.setFieldValue(`owners[${pocIdx}].${pocKey}`, pocValue);
        }
      });
    } else {
      if (owners.length) {
        // separate owner record will be created and marked as isOwnerPcOnly
        pointOfContact.isOwnerPcOnly = true;
        formik.setFieldValue('ownerPcOnly', pointOfContact);
        _.forEach(owners, (o, idx) => formik.setFieldValue(`owners[${idx}].isOwnerPc`, false));
      } else {
        // if no owners section in template, set Point Of Contact as the only owner
        formik.setFieldValue(`owners`, [pointOfContact]);
        formik.setFieldValue('ownersQuantity', 1);
      }
    }
  };


  if (isDisplayed) {
    if (isOwnersSection) {
      return <>
        <h2 style={config.title.config} className={classes.sectionHeader}>
          {sectionHeader}
        </h2>
        <Owners
          config={config}
          locale={intl.locale}
          externalData={externalDataState}
        />
      </>
    }

    if (isDocumentsSigningSection) {
      return <DocumentsSigningSection
        config={config}
        locale={intl.locale}
        externalData={externalDataState}
      />
    }

    if (isSignaturesSection) {
      return (
        <Signatures
          config={config}
          locale={intl.locale}
          disclosureLangEN={publicAccount.vendorProfile.dcrDisclosureLanguage}
          disclosureLangES={
            publicAccount.vendorProfile.dcrDisclosureLanguageSpanish
          }
          dcrDisclosureTermsEN={publicAccount.vendorProfile.dcrDisclosureTerms}
          dcrDisclosureTermsES={publicAccount.vendorProfile.dcrDisclosureTermsSpanish}
          dcrDisclosureUseDefaultTerms={publicAccount.vendorProfile.dcrDisclosureUseDefaultTerms}
          template={template}
          externalData={externalDataState}
        />
      );
    }

    return (
      <Grid container spacing={4}>
        <>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h3 style={config.title.config} className={classes.sectionHeader}>
              {sectionHeader}
            </h3>
          </Grid>
          {config.fields.map((field: FieldType, index: number) => {
            if (field.type.startsWith('location')) {
              if (!multiLocationEnabled || !_.isEmpty(vendorContactId))
                return null;

              return (
                <Element
                  item={field}
                  locale={intl.locale}
                  key={index}
                  externalData={externalDataState}
                  externalCallbacks={externalCallbacks}
                />
              );
            } else {
              return (
                <Element
                  item={field}
                  key={index}
                  locale={intl.locale}
                  externalData={externalDataState}
                  externalCallbacks={externalCallbacks}
                />
              );
            }
          })}
        </>
      </Grid>
    );
  }

  return null;
};
