import { Box, Grid, TextField, InputLabel } from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { FieldType } from '../types';
import EditIcon from '@mui/icons-material/Edit';
import { CommonDataContext } from '../../context';
import { FieldComponentFactory } from '../FieldComponentFactory';
import { Constants } from '../../constants';
import _ from 'lodash';


enum FieldViewEnum {
  Masked = 'masked',
  Normal = 'normal',
}

type FieldView = FieldViewEnum.Masked | FieldViewEnum.Normal;

export const MaskedValueField = ({
  itemData,
  value,
  locale,
  displayLabel,
  isNeedToMoveLabelOutOfInput,
  externalData,
  externalCallbacks,
}: {
  itemData: FieldType;
  value: any;
  locale: string;
  displayLabel?: string;
  isNeedToMoveLabelOutOfInput?: boolean;
  externalData?: any,
  externalCallbacks?: any,
}) => {
  const { id: fieldId, config: fieldConfig } = itemData;
  const { isMobileView } = useContext(CommonDataContext);
  const formik: FormikProps<any> = useFormikContext();
  const [view, setView] = useState<FieldView>(FieldViewEnum.Masked);
  const [itemDataConfigNormal, setItemDataConfigNormal] = useState<FieldType>();
  displayLabel = displayLabel || _.get(fieldConfig, `displayLabel.${locale}`, '');



  const handleEditClick = () => {
    setView(FieldViewEnum.Normal);
    const itemDataConfigNormal = {
      ...itemData,
      type: itemData.originalType!,
      config: {
        ...itemData.config,
        fieldName: _.replace(itemData.config.fieldName, Constants.maskedFieldNameSuffix, '')
      }
    };
    setItemDataConfigNormal(itemDataConfigNormal);
    // TODO: needs to replace original (masked) oca config with itemDataConfigNormal to avoid issues when user go back from another page
  };

  return (
    <>
      {view === FieldViewEnum.Masked ? (
        <Box
          component={Grid}
          item
          xl={_.get(fieldConfig, 'columns.xl', 12)}
          lg={_.get(fieldConfig, 'columns.lg', 12)}
          md={_.get(fieldConfig, 'columns.md', 12)}
          sm={_.get(fieldConfig, 'columns.sm', 12)}
          xs={_.get(fieldConfig, 'columns.xs', 12)}
          display={{
            xl: !itemData.config.displayed ? 'none' : 'block',
            lg: !itemData.config.displayed ? 'none' : 'block',
            md: !itemData.config.displayed ? 'none' : 'block',
            sm: !itemData.config.displayed ? 'none' : 'block',
            xs: !itemData.config.displayed ? 'none' : 'block',
          }}
        >
          {isNeedToMoveLabelOutOfInput && <InputLabel className='outer-label'>{displayLabel}</InputLabel>}
          <TextField
            key={fieldId}
            name={fieldConfig.fieldName}
            disabled={true}
            fullWidth={fieldConfig.fullWidth}
            variant={isMobileView ? "outlined" : "standard"}
            label={isNeedToMoveLabelOutOfInput ? '' : displayLabel}
            helperText={_.get(fieldConfig, `helperText.${locale}`, '')}
            value={_.get(formik, `values.${itemData.config.fieldName}`) || ''}
            InputProps={{ endAdornment: <EditIcon style={{ cursor: 'pointer', color: 'black' }} fontSize='small' onClick={handleEditClick} /> }}
          />
        </Box>
      ) : (
        <>
          {itemDataConfigNormal && FieldComponentFactory(
            itemDataConfigNormal,
            _.get(formik, `values.${itemDataConfigNormal.config.fieldName}`),
            locale,
            isMobileView,
            externalData,
            externalCallbacks
          )}
        </>
      )}
    </>
  );
};
