import tradeRefTaskCompletionFields from "../../sample-config/tradeRefTaskCompletionFields.json";
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Alert, Container, Grid, LinearProgress, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { makeStyles } from '@mui/styles';
import {
  Q_CREDIT_APP_PUBLIC,
  UPDATE_CREDIT_APPLICATION_V2_QUERY,
  UPDATE_TASK_STATUS,
} from '../../../api';
import { Account, CreditApplication, Reference, Task } from '@trnsact/trnsact-shared-types';
import format from 'date-fns/format';
import { getURLParameter } from '../../../helpers';
import { Formik, FormikValues } from "formik";
import { FieldType } from "../../types";
import { QueryCreditApplicationPublic, TaskStatuses } from "../../../api/types";
import { ReferenceForm } from "./ReferenceForm";


interface Props {
  task: Task;
  publicAccount: Account
}

export const TradeReferencePage = ({
  task,
  publicAccount,
}: Props) => {

  const classes = useStyles();

  const [creditApp, setCreditApp] = useState<CreditApplication>();
  const [reference, setReference] = useState<Reference>();
  const [isTaskCompleted, setIsTaskCompleted] = useState<boolean>(task.status === TaskStatuses.Complete);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  //@ts-ignore
  const [groupedFields, setGroupedFields] = useState<Record<string, FieldType[]>>(tradeRefTaskCompletionFields);

  const [updateCreditAppRequest] = useMutation(UPDATE_CREDIT_APPLICATION_V2_QUERY);

  const [updateTaskStatus] = useMutation(UPDATE_TASK_STATUS, {
    onCompleted() {
      setIsTaskCompleted(true);
    },
    onError() {
      setIsTaskCompleted(false);
    },
  });

  const referenceId = getURLParameter('referenceId');
  const creditAppId = getURLParameter('creditAppId');


  useQuery<QueryCreditApplicationPublic>(Q_CREDIT_APP_PUBLIC, {
    skip: !creditAppId,
    variables: {
      id: creditAppId,
    },
    onCompleted(data) {
      const reference = data.creditApplicationPublic?.references?.find(r => r!.referenceId === referenceId);
      setCreditApp(data.creditApplicationPublic);
      setReference(reference!);
      setIsLoading(false);
    },
  });

  const handleSubmit = async (values: FormikValues) => {

    const getJsonRows = (fields: FieldType[]) => {
      return fields.reduce((acc: any, field: FieldType) => {

        const fieldValue = field.type === "select-field"
          ? field.config.fieldOptions!.find(o => o.optionValue === values[field.config.fieldName])?.displayLabel.en
          : values[field.config.fieldName];

        if (!fieldValue) {
          return acc;
        }

        const rowToAdd = {
          fieldKey: field.config.fieldName,
          label: field.config.displayLabel!.en,
          value: fieldValue,
        };
        return [...acc, rowToAdd];
      }, []);
    };

    if (!reference) {
      throw (`Can't find appopriate reference`);
    }

    try {
      setIsLoading(true);

      const jsonData = [
        ...getJsonRows(groupedFields.common),
        ...getJsonRows(groupedFields.mannerOfPayment),
        ...getJsonRows(groupedFields.security),
        ...getJsonRows(groupedFields.comments),
        ...getJsonRows(groupedFields.completedBy),
      ];

      const input = {
        references: [
          {
            referenceId,
            tasksCompletionData: [
              ...reference.tasksCompletionData || [],
              {
                taskId: task.taskId,
                data: jsonData
              }
            ]
          }
        ]
      };

      const result = await updateCreditAppRequest({ variables: { creditAppId, input } });

      if (!result?.data?.updateCreditApplicationV2) {
        throw (`Failed to update credit app`);
      }

      await updateTaskStatus({
        variables: {
          taskId: task.taskId,
          status: TaskStatuses.Complete
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      maxWidth='lg'
      className={classes.containerRoot}
    >
      <Typography variant='h4' align="center" className={classes.pageHeader}>Credit Reference Request</Typography>

      <Grid container spacing={3} mt={4}>

        {isLoading && <LinearProgress style={{ width: "100%" }} />}

        {!isLoading && !isTaskCompleted && <>
          <Grid item>
            <Typography variant='h6' className={classes.sectionSubtitle}>Requested by:</Typography>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={4}>
              <Typography variant='subtitle2' component='span' className={classes.inputLabel}>Company Name: </Typography>
              <Typography variant='body1' component='span'>{publicAccount.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' component='span' className={classes.inputLabel}>Contact Name: </Typography>
              <Typography variant='body1' component='span'>
                {task.assignedByUser}
                {`${task.createdByUserProfile?.title ? (', ' + task.createdByUserProfile?.title) : ''}`}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant='subtitle2' component='span' className={classes.inputLabel}>Phone: </Typography>
              <Typography variant='body1' component='span'>{task.createdByUserProfile?.phoneNumber}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' component='span' className={classes.inputLabel}>Email: </Typography>
              <Typography variant='body1' component='span'>{task.createdByUserProfile?.email}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' component='span' className={classes.inputLabel}>Requested Date: </Typography>
              <Typography variant='body1' component='span'>{format(new Date(task.createdDateTime!), 'MM/dd/yyyy')}</Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant='h6' className={classes.sectionSubtitle}>On behalf of:</Typography>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={4}>
              <Typography variant='subtitle2' component='span' className={classes.inputLabel}>Applicant Name: </Typography>
              <Typography variant='body1' component='span'>{creditApp?.businessName}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' component='span' className={classes.inputLabel}>Contact: </Typography>
              <Typography variant='body1' component='span'>
                {`${creditApp?.primaryContact?.firstName} ${creditApp?.primaryContact?.lastName}`}
                {`${creditApp?.primaryContact?.title ? (', ' + creditApp?.primaryContact?.title) : ''}`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' component='span' className={classes.inputLabel}>Phone: </Typography>
              <Typography variant='body1' component='span'>{creditApp?.primaryContact?.phone || '-'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' component='span' className={classes.inputLabel}>Email: </Typography>
              <Typography variant='body1' component='span'>{creditApp?.primaryContact?.email || '-'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' component='span' className={classes.inputLabel}>Address: </Typography>
              <Typography variant='body1' component='span'>{creditApp?.primaryContact?.address || '-'}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography>The company listed above has given your name as a credit reference. Please provide the requested information by completing the below secure form. All information will be kept in strict confidence. Thank you for your assistance.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              If you have any questions, please contact me at {' '}
              {task.createdByUserProfile?.email ? <strong className={classes.anyQuestionItemLabelWithIcon}><EmailIcon fontSize='small' /> {task.createdByUserProfile.email}, </strong> : ''}
              {task.createdByUserProfile?.phoneNumber ? <strong className={classes.anyQuestionItemLabelWithIcon}> <PhoneIcon fontSize='small' /> {task.createdByUserProfile.phoneNumber}, </strong> : ''}
              {task.assignedByUser ? <strong>{task.assignedByUser}, </strong> : ''}
              {task.createdByUserProfile?.title ? <strong>{task.createdByUserProfile.title}</strong> : ''}
              .
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h6' className={classes.sectionSubtitle}>Reference Form</Typography>
          </Grid>

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validate={() => { }}
            initialValues={{
              security: "none",
              name: reference?.contactName,
              title: reference?.jobTitle,
              phone: reference?.contactPhone,
              signature: reference?.contactName,
            }}
            onSubmit={() => { }}
          >
            {({ values, errors }) =>
              <ReferenceForm
                handleSubmit={handleSubmit}
                groupedFields={groupedFields}
                setGroupedFields={setGroupedFields}
                values={values}
                errors={errors}
              />
            }
          </Formik>
        </>}

        {!isLoading && isTaskCompleted && <Grid item xs={12}>
          <Alert severity="success">The request has been successfully completed!</Alert>
        </Grid>}
      </Grid>
    </Container >
  );
};

const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      backgroundColor: 'white',
      marginTop: '20px',
      marginBottom: '20px',
      "& [name=signature]": {
        fontFamily: "'Homemade Apple', cursive",
      }
    },
    pageHeader: {
      "&.MuiTypography-root": {
        fontWeight: 500,
      }
    },
    inputLabel: {
      color: '#424242',
    },
    anyQuestionItemLabelWithIcon: {
      marginLeft: 2,
      "& svg": {
        verticalAlign: 'sub',
      }
    },
    sectionSubtitle: {
      "&.MuiTypography-root": {
        margin: '0 0 -20px 0',
      }
    },
  };
});
